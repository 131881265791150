// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

// $ion-font-family-base: "Urbanist", sans-serif;
// $primary-brand-color: #3256a2;
// $primary-brand-color--opacity40percent: rgba(50, 86, 162, 0.5);
// $secondary-brand-color: #e92429;

:root {
  --primary-brand-color: #3256a2; /* Default color */
  --primary-brand-color-opacity40percent: rgba(
    50,
    86,
    162,
    0.5
  ); /* Default color */
  --secondary-brand-color: #e92429; /* Default color */
  --ion-font-family-base: "Urbanist", sans-serif;
  // --logo-url: url("https://s3.us-east-1.amazonaws.com/dev.knostos.assets/user-portals/ultimed-pharmacy/ultimedPharmacyLogo.png");
  --logo-url: url("https://lirp.cdn-website.com/b2238586/dms3rep/multi/opt/Medplus+Solutions-232w.png");
  --login-url: url("../assets/images/loginImage.png");
  // --login-logo-url: url("https://s3.us-east-1.amazonaws.com/dev.knostos.assets/user-portals/ultimed-pharmacy/ultimedLogoWhite.png");
  --login-logo-url: url("https://lirp.cdn-website.com/b2238586/dms3rep/multi/opt/Logo+Medplus+blanco-237w.png");
}

/* Use CSS variables in SCSS */
$ion-font-family-base: var(--ion-font-family-base);
$primary-brand-color: var(--primary-brand-color);
$primary-brand-color--opacity40percent: var(
  --primary-brand-color-opacity40percent
);
$secondary-brand-color: var(--secondary-brand-color);

$logo-url: var(--logo-url);
$login-url: var(--login-url);
$login-logo-url: var(--login-logo-url);

// $gray-background: #f5f5f5;
$gray-background: #f4f5f8;
$gray-3: #959595;
$gray-4: #d9d9d9;

$black: #201c1c;

// $primary-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
$primary-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);

$red-1: #f2506e;
$red-bg: #ff98a34d;

$green-1: #00952d;
$green-bg: #d5ffd0;

$blue-1: #3072ff;
$blue-bg: #d8e7ff;

$blue-bright: #007aff;

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
