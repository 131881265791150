@import "./variables.scss";
@import "./responsive.scss";

body,
ion-content {
  font-family: "Urbanist", sans-serif;
}

ion-content {
  height: 100vh;
  --padding-top: 0;
}

ion-content .inner-scroll.scroll-y.overscroll {
  padding: 0 !important;
}

ion-select,
ion-select-option,
ion-toast,
alert-radio-label,
alert-head,
alert-wrapper,
alert-button {
  font-family: "Urbanist", sans-serif !important;
}

ion-button {
  /* Remove default padding, font style, etc. */
  --padding: 0;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  background: none;
  border: none;
  box-shadow: none;

  flex: unset;
  flex-direction: unset;

  /* Optionally, remove any hover/focus effects */
  --background: none;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
}

.align-flex-start {
  align-items: flex-start !important;
}

a {
  text-decoration: none;
  color: $black;
}

h1 {
  margin: 0;
}

button {
  background-color: $primary-brand-color;
  padding: 8px;
  border-radius: 10px;
  border: none;
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

input,
textarea {
  border: none;
  outline: none;
  background-color: white;
}

ion-header {
  padding: 8px 16px;
  --background: transparent;
  --border-color: transparent; // Optional: removes the border line if there’s any
  position: absolute;
  z-index: 10;
  background-color: #ffffff00;
  backdrop-filter: blur(10px);
  width: 100%;
  box-shadow: none !important;
}

.ios ion-header {
  padding-top: 65px;
}

.app-container {
  // padding: 0 16px;
  width: calc(100% - 32px);
  margin: 0 auto;
}

.mt-16 {
  margin-top: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ver-hor-centered-absolute-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.vertical-wrap-g0 {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.vertical-wrap-g8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vertical-wrap-g16 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vertical-wrap-g24 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.vertical-wrap-g32 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.vertical-wrap-g40 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.vertical-wrap-g48 {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.vertical-wrap-g56 {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.vertical-wrap-g64 {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.w-100 {
  width: 100%;
}

input,
textarea::file-selector-button {
  width: 200px;
  // height: 200px;
  border: 2px solid $primary-brand-color;
  border-style: dashed;
  border-radius: 10px;
  background-color: white;
}

// flex abbreviations
// FullWidth Flex Justify contentCenter and align items center
.fw-fcc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

ol {
  counter-reset: list-counter;
  /* Initializes the counter */
  list-style: none;
  /* Removes default list styling */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

li {
  display: flex;
  align-items: center;
  /* Centers content vertically */
  counter-increment: list-counter;
  /* Increments counter for each li */
}

li::before {
  content: counter(list-counter) ". ";
  /* Displays the counter */
  margin-right: 8px;
  /* Spacing between number and list item text */
  font-weight: bold;
  /* Optional: makes the number bold */
}

input,
textarea {
  width: 100%;
  border: 1px solid $gray-4;
  border-radius: 10px;
  color: $primary-brand-color;
  padding: 16px;
  font-size: 20px;
  font-weight: 700;
  transition: 300ms;
  margin-top: 4px;
}

input::placeholder,
textarea::placeholder {
  color: $gray-4;
  font-weight: 400;
  font-size: 16px;
}

input:focus-visible,
textarea:focus-visible,
input:focus-within,
textarea:focus-within,
input:focus,
textarea:focus,
input:hover,
textarea:hover,
input:visited,
textarea:visited,
input:active,
textarea:active {
  box-shadow: $primary-shadow;
}

.med-title,
.med-title-large {
  font-size: 24px;
  font-weight: 700;
  color: $primary-brand-color;
  margin-bottom: 8px;
}

.med-title-large {
  font-size: 32px;
  letter-spacing: -1px;
}

.med-button-primary,
.med-button-white {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: $primary-brand-color;
  color: white;
  font-weight: 700;
  letter-spacing: -0.22px;
  border-radius: 50px;
  width: 100%;
  font-size: 22px;
  height: 50px;
  transition: 500ms;
  border: 2px solid $primary-brand-color;
}

.med-button-white svg {
  fill: currentColor;
  color: $primary-brand-color;
}

.med-button-primary:hover {
  // box-shadow: $primary-shadow;
  color: $primary-brand-color;
  background-color: white;
}

.med-button-white {
  border: 1px solid $primary-brand-color;
  background-color: white;
  color: $primary-brand-color;
}

.med-button-white:hover {
  color: white;
  background-color: $primary-brand-color;
}

.med-divider {
  width: 100%;
  background-color: $gray-4;
  height: 1px;
}

.forgot-password {
  color: $gray-3;
  font-size: 16px;
  text-decoration-line: underline;
}

.row-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

// Scroll bar styling only for the web this shall not apply for the
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $blue-bg;
  width: 8px;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-brand-color;
}

.blurred-circle {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: -30px;
  right: -30px;
  z-index: 0;
  background-color: $blue-bg;
  // border: 1px solid $blue-bright;
  filter: blur(5px);
  border-radius: 50%;
}

.p-8 {
  padding: 8px !important;
}
.p-16 {
  padding: 16px !important;
}
