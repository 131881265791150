@import "./variables.scss";

@media only screen and (max-width: 768px) {
  .pdf-container .document-review-layout,
  app-tiffviewer .document-review-layout {
    width: 97%;
    position: absolute;
    bottom: -180px;
    transition: 1000ms;
    height: 200px;
    flex-direction: row;
    z-index: 99999;
  }

  .document-review-layout:hover {
    bottom: 0;
  }

  .document-review-layout .med-title {
    display: none;
  }

  .document-review-layout .document-review-tag {
    position: absolute;
    height: 30px;
    color: white;
    background-color: #007aff;
    font-size: 20px;
    top: -30px;
    border-radius: 10px 10px 0 0;
    padding: 2px 15px;
    left: 10px;
  }

  // ion-modal app-tiffviewer {
  //   display: block !important;
  //   position: absolute;
  //   top: 20px;
  //   right: 20px;
  //   background-color: red;
  // }
}

@media only screen and (max-width: 1023px) {
  .container-body app-notifications {
    display: none;
  }

  .sidebar {
    display: none;
  }

  ion-modal .sidebar {
    display: flex;
  }

  .sized-box {
    display: block;
  }

  app-appbar {
    display: flex;
  }

  app-patient-info {
    display: none;
  }

  app-pdfviewer {
    display: none;
  }

  .document-review-layout.desktopReview {
    display: none;
  }

  .documents-to-review-wrapper app-pdfviewer {
    display: none;
    width: 100%;
    height: 100%;
  }

  .documents-to-review-wrapper .horizontal-wrapper {
    display: flex;
  }

  .document-card {
    // height: 250px;
  }

  app-document-card {
    width: 100%;
  }

  .facility-cycle-card-wrapper {
    width: 100%;
  }

  .register-new-patient {
    display: none;
  }

  .desktop-notification-side app-head-stats-cards {
    display: none;
  }

  .resolve-wrapper {
    width: 100px;
  }

  .resolve-wrapper span {
    display: none;
  }

  .patient-general-items-wrapper {
    flex-direction: column;
  }

  .documents-to-review-wrapper .documents-wrapper {
    width: 100%;
  }

  .order-prescription-button {
    width: 100% !important;
  }

  .order-card {
  }

  .orders-head-banner {
    display: none;
  }
}

@media only screen and (min-width: 1023px) {
  .close-modal {
    display: none;
  }

  .head-info-card.isNotification {
    display: none;
  }

  .pdf-container .document-review-layout {
    display: none;
  }

  .app-container {
    height: 100%;
    width: 100%;
    padding: 16px;
  }

  .heading-blur-bg {
    background-image: url(../assets/images/headingImage2.png);
  }

  .info-icon {
    width: 50px;
    height: 50px;
  }

  .info-label {
    font-size: 15px;
  }

  .info-value {
    font-size: 30px;
    font-weight: 900;
  }

  .web-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 32px;
  }

  app-sidebar {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }

  .vertical-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: calc(100% - 232px);
    height: 100%;
  }

  .horizontal-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    width: calc(100% - 128px);
    height: 100%;
  }

  .medications-cards-wrapper {
    min-width: 300px;
    max-width: 30vw;
    width: 100%;
  }

  .container-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
  }

  app-notifications .app-container {
    padding: 8px;
    background-color: white;
    height: 83vh;
    border-radius: 20px;
    margin: 0;
    // box-shadow: $primary-shadow;
    border: 2px solid $gray-background;
    overflow: auto;
  }

  app-navbar {
    display: none;
  }

  app-appbar {
    display: none;
  }

  .blockers-list {
    width: 60%;
    height: 97vh;
    overflow: auto;
    margin: 0;
    justify-content: flex-start;
    padding: 0 16px;
  }

  .blockers-list app-head-stats-cards {
    display: none;
  }

  .isOrdersPage .blockers-list {
    width: 100%;
  }

  .sized-box {
    display: none;
  }

  // Patients responsiveness ui
  .patient-rows-wrapper {
  }

  .user-container {
    width: 100%;
  }

  .user-container .username {
    font-size: 14px;
  }

  .patient-rows-wrapper {
    height: 96vh;
    width: 40%;
    box-shadow: $primary-shadow;
  }

  app-patient-info {
    width: 77%;
    height: 96vh;
    overflow: auto;
    border-radius: 20px;
    box-shadow: $primary-shadow;
  }

  .patient-info-wrapper {
    width: 100%;
  }

  .login-form {
    display: flex;
    width: 500px;
    box-shadow: $primary-shadow;
    position: unset;
    border-radius: 20px;
  }

  .sign-in-header-image .overlay {
    background: linear-gradient(270deg, rgb(255 255 255 / 0%) 0%, #ffffff 100%);
  }

  .sign-in-header-image .overlay svg,
  .sign-in-header-image .overlay .login-slogan,
  .sign-in-header-image .overlay .logo {
    display: none;
  }

  .sign-in-header-image {
    height: 100vh;
    width: 50vw;
  }

  .desktop-slogan {
    display: flex;
    text-align: center;
    font-size: 28px;
  }

  .desktop-login-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin: 60px;
  }

  .order-exp-date-label {
    display: none;
  }

  .order-card {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .order-card .order-patient {
    flex-direction: column;
    border-bottom: none;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
  }

  .order-exp-date {
    font-size: 12px;
  }

  .isOrdersPage .vertical-wrapper {
    width: calc(100% - 232px) !important;
  }

  .isOrdersPage .order-info {
    min-width: 100px;
    max-width: 120px;
  }

  .isDocumentsPage .vertical-wrapper {
    gap: 0;
    height: 97vh;
  }

  .isDocumentsPage .document-card {
    width: 100%;
  }

  app-document-card {
    width: 290px;
    max-width: 290px;
    min-width: 290px;
  }

  .isDocumentsPage .documents-wrapper {
    width: 316px;
    max-width: 316px;
    min-width: 316px;
    height: calc(100vh - 74px);
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    flex-direction: row;
  }

  app-pdfviewer {
    width: 100%;
    height: 100%;
  }

  .isDocumentsPage .horizontal-wrapper {
    gap: 8px;
  }

  .register-new-patient {
    font-size: 22px;
    flex-direction: column;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    width: 100%;
  }

  app-notifications {
    width: 100%;
    height: 100%;
  }

  .desktop-notification-side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 29%;
    height: 97vh;
    flex-direction: column;
  }

  app-head-stats-cards {
    width: 100%;
  }

  app-cycle-card {
    width: 100%;
  }

  .desktop-notification-side {
    width: 40%;
  }
}

@media only screen and (min-width: 1440px) {
  .blockers-list {
    width: 70%;
    height: 97vh;
    margin: 0;
    justify-content: flex-start;
  }

  .desktop-notification-side {
    width: 30%;
  }

  .order-prescription-label {
    width: 115px;
    font-size: 12px;
    text-align: center;
  }

  .order-card .order-prescription-button {
    width: unset;
    gap: 4px;
  }

  .order-card .order-patient {
    width: 200px;
  }

  .order-card .order-steps {
    width: 600px;
  }

  .order-card .order-info,
  .order-info {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-card .order-prescription-button {
    width: 200px;
  }

  .register-new-patient {
    font-size: 32px;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1920px) {
}
